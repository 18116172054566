import React from "react";
import Content from '../views/LandingFleet/SignUp';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Tilmeld dig til flådestyringtjenesten",
    lang: 'da-DK',
    description: "Prøv Fleet gratis i 30 dage. Udfyld vores korte formular og modtag et tilbud.",
    meta: [{
        name: 'keywords',
        content: 'Tilmeld dig'
      },
      {
        name: 'robots',
        content: 'noindex',
      }]
  }} />
);